import { connect } from 'react-redux';
import Component from './component';

import { list as listUsers } from 'Redux/modules/trainer/users/actions';
import {
    update,
    create,
    changeCompleted,
} from 'Redux/modules/trainer/userHomeworks/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            listUsers: dispatch(listUsers),
            update: dispatch(update),
            create: dispatch(create),
            changeCompleted: dispatch(changeCompleted),
        },
    }),
)(Component);