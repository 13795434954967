import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .admin-element-editor {
            .editor-wrapper {
                display: block !important;

                .forms-wrapper {
                    width: 100% !important;

                    .form-wrapper {
                        .admin-form-generator {
                            .form-elements { 
                                .date-picker {
                                    .input-external-wrapper {
                                        .input-internal-wrapper {
                                            .react-datepicker-popper {
                                                left: -6em !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: ${variables.mobileL}) {
            .admin-element-editor {
                .editor-wrapper {
                    .forms-wrapper {
                        .form-wrapper {
                            .admin-form-generator {
                                .form-elements { 
                                    .date-picker {
                                        .input-external-wrapper {
                                            .input-internal-wrapper {
                                                .react-datepicker-popper {
                                                    left: 0em !important;
                                                    width: 20em;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `;
