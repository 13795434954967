import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TRAINER_DASHBOARD, TRAINER_ADEPTS, TRAINER_ADEPTS_MANAGE } from 'Consts/routes';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import Editor from 'Components/trainer/userHomeworks/Editor';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import { TAB_USER_HOMEWORKS } from 'Components/pages/trainer/UsersManage/component';

export const TAB_DATA = 'data';

export default class TrainerUserHomeworkCreate extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    render() {
        const { location, history } = this.props;
        const queryObject = parseQueryToObject(location.search);
        
        return (
            <StyledComponent
                className="trainer-user-homework-create"
                styles={require('./styles')}
            >
                <PageContent
                    elementMode={true}
                    breadcrumbs={[{
                        to: withVariables(TRAINER_DASHBOARD.path),
                        label: 'Dashboard',
                    }, {
                        to: withVariables(TRAINER_ADEPTS.path),
                        label: 'Adepci',
                    }, {
                        to: withVariables(TRAINER_ADEPTS_MANAGE.path, { id: queryObject.userId }, { tab: TAB_USER_HOMEWORKS }),
                        label: 'Prace domowe',
                    }]}
                >
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Dodaj nową pracę domową"
                        tabs={[{
                            key: TAB_DATA,
                            label: 'Dane',
                            children: (
                                <Editor
                                    location={location}
                                    history={history}
                                    predefinedState={{
                                        userId: queryObject.userId,
                                    }}
                                />
                            ),
                        }]}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}