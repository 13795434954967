import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TRAINER_ADEPTS_MANAGE } from 'Consts/routes';

import { parseQueryToObject } from 'Utils/querystring';
import { toApiFormat as dateToApiFormat } from 'Utils/date';
import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';
import { TAB_USER_HOMEWORKS } from 'Components/pages/trainer/UsersManage/component';

export default class TrainerUserHomeworkEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            update: PropTypes.func.isRequired,
            create: PropTypes.func.isRequired,
            listUsers: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        predefinedState: PropTypes.object,
    };
    static defaultProps = {
        data: null,
        predefinedState: {},
    };

    state = {
        formState: {},
    };

    componentDidMount = () => {
        const { data } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),              
            },
        }));
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { data, predefinedState } = this.props;
        const { formState } = this.state;

        if (
            data && JSON.stringify(data) !== JSON.stringify(prevProps.data) ||
            predefinedState && JSON.stringify(predefinedState) !== JSON.stringify(prevProps.predefinedState)
        ) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data || {}),
                },
            }));
        }

        if(prevState?.formState?.productId && JSON.stringify(formState.offerId) !== JSON.stringify(prevState.formState.offerId)) {
            this.setState({
                formState: {
                    ...formState,
                    productId: null,
                },
            });
        }
    }

    formStateToApi = data => ({
        ...data,
        deadlineAt: dateToApiFormat(data.deadlineAt, 'datetime', true),
    })

    dataToFormState = data => {
        const { predefinedState } = this.props;

        return {
            ...data,
            ...predefinedState,
        };
    }

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onCreate = formState => {
        const { actions, history, location } = this.props;

        const { search } = location;
        const queryObject = parseQueryToObject(search);

        return actions.create({
            ...this.formStateToApi(formState),
            userId: queryObject.userId,
        }).then(response => {
            history.push(
                withVariables(
                    TRAINER_ADEPTS_MANAGE.path,
                    { id: queryObject.userId },
                    { tab: TAB_USER_HOMEWORKS }
                )
            );
        });
    }

    onUpdate = formState => {
        const { actions, data } = this.props;

        return actions.update({
            ...formState,
            id: data.id,
            ...this.formStateToApi(formState),
        });
    }

    render() {
        const { location, history } = this.props;
        const { formState } = this.state;
        
        return (
            <StyledComponent
                className="trainer-user-homework-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    styleVersion={2}
                    forms={[{
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        submitButtonStyle: 'hollow',
                        styleVersion: 'transparentLight',
                        elements: [{
                            type: 'textarea',
                            name: 'description',
                            label: 'Opis',
                            inputProps: {
                                styleVersion: 2,
                            },
                            required: true,
                        }, {
                            type: 'datePicker',
                            name: 'deadlineAt',
                            label: 'Termin wykonania pracy',
                            inputProps: {
                                datePickerProps: {
                                    dateFormat: 'yyyy-MM-dd HH:mm',
                                    showTimeSelect: true,
                                    timeIntervals: 15,
                                },
                                style: 'version-2',
                            },
                        }, {
                            type: 'switch',
                            name: 'isCompleted',
                            label: 'Wykonana/niewykonana',
                            inputProps: {
                                style: 'light',
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}